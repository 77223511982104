import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useAppState } from "../../contexts";
import { origins } from "../../data/origins";
import { TOrder } from "../../types/Order";
import { TProduct } from "../../types/Product";
import { formatDate, formatNumber, formatShortDate } from "../../utils/i18n";
import { Label } from "../Label";
import { ProductQuantitySelector } from "../ProductQuantitySelector";
import { Text } from "../Text";
import { theme } from "../theme";
import { useSubscription } from "../../hooks/Subscriptions";
import { useHistory } from "react-router-dom";
import { Button } from "../Button";
import { MSLTag } from "../MSLTag";
import { useUser } from "../../hooks/Users";

interface Props {
  product: TProduct;
  isPreOrder: boolean;
  withBanner: boolean;
  order?: TOrder;
  onSelectProduct: (product: TProduct) => void;
}

export const Product: React.FC<Props> = ({
  withBanner = false,
  product,
  order,
  isPreOrder,
  onSelectProduct,
}) => {
  const { t } = useTranslation();
  const { locale } = useAppState();
  const [lang] = locale.split("-");
  const { data: subscription } = useSubscription();
  const history = useHistory();
  const { data: user } = useUser();

  const handleCardClicked = () => onSelectProduct(product);
  // @ts-ignore
  const origin = origins[lang][product?.origin];
  const productInCart = product.quantity
    ? product.quantity
    : order?.status === "open"
    ? order?.products?.find((p) => p.pid === product.pid)?.quantity
    : 0;

  return (
    <ProductCard className={"product-card"}>
      {withBanner && (
        <>
          {product.is_promo && (
            <PromoLabel size="md">{product.promotion}%</PromoLabel>
          )}
          {product.inventory <= 0 && (
            <NoStockLabel size="md">{t("Catalog.no.stock")}</NoStockLabel>
          )}
        </>
      )}
      <PresentationWrapper>
        <TopWrapper onClick={handleCardClicked}>
          <ImgWrapper>
            {product?.images && product?.images.length > 0 && (
              <img src={product.images[0]} loading={"lazy"} />
            )}{" "}
            {product?.best_before && (
              <BestBefore>
                <Tag>{formatDate(locale, new Date(product.best_before))}</Tag>
              </BestBefore>
            )}
          </ImgWrapper>
        </TopWrapper>
        <InfoWrapper>
          <LocationText size="sm">
            {`${product.brand.copy[`${lang}`].name}${
              origin ? ` | ${origin}` : ""
            }`}
          </LocationText>
          <LabelWrapper onClick={handleCardClicked}>
            <ProductLabel size="mdx" weight={"bold"}>
              {product?.copy[lang]?.name} ({product.volume / 100}
              {product.unit})
            </ProductLabel>
          </LabelWrapper>

          {/* Short description, 2 lines max */}
          <div>
            <ProductDescription>
              {product.copy[lang].description}
            </ProductDescription>
          </div>
          <PriceRowMobile>
            <PriceWrapper>
              <UnitPriceText size="mdx">
                {`${formatNumber(locale, product.unit_price / 100)}$ / ${
                  product.unit_price_unit
                }`}
              </UnitPriceText>
              <PriceText size="mdx">
                {product.is_promo && (
                  <s>{`${formatNumber(
                    locale,
                    (product.regular_price * (productInCart || 1)) / 100
                  )}$`}</s>
                )}
                <PriceTextBottom>
                  {`${formatNumber(
                    locale,
                    (product.price * (productInCart || 1)) / 100
                  )}$`}
                </PriceTextBottom>
              </PriceText>
            </PriceWrapper>

            {user && user.prime_status === "active" && (
              <MSLTag
                browser={false}
                mobile={true}
                price={product.member_price}
                size={"mdx"}
              />
            )}
          </PriceRowMobile>
        </InfoWrapper>
        <IconWrapper>
          <TagsWrapper>
            {product.is_saved && <SavedTag key="saved">{t("Saved")}</SavedTag>}
            {!product.is_saved && (
              <NotSavedTag key="notSaved">{t("Regular")}</NotSavedTag>
            )}
            {product.is_featured && <NewTag key="new">{t("New")}</NewTag>}
            {product.is_local && <LocalTag key="local">{t("Quebec")}</LocalTag>}
            {product.tags?.map((tag) => (
              <Tag key={tag.tid}>{tag.copy[lang]?.name}</Tag>
            ))}
          </TagsWrapper>

          {user && user.prime_status === "active" && (
            <MemberPriceWrapper>
              <MSLTag
                browser={true}
                mobile={false}
                price={product.member_price}
                size={"mdx"}
              />
            </MemberPriceWrapper>
          )}
        </IconWrapper>
      </PresentationWrapper>
      <Separator />
      <BottomWrapper>
        <PriceRow>
          <PriceWrapper>
            <UnitPriceText size="mdx">
              {`${formatNumber(locale, product.unit_price / 100)}$ / ${
                product.unit_price_unit
              }`}
            </UnitPriceText>
            <PriceText size="mdx">
              {product.is_promo && (
                <s>{`${formatNumber(
                  locale,
                  (product.regular_price * (productInCart || 1)) / 100
                )}$`}</s>
              )}
              <PriceTextBottom>
                {`${formatNumber(
                  locale,
                  (product.price * (productInCart || 1)) / 100
                )}$`}
              </PriceTextBottom>
            </PriceText>
          </PriceWrapper>

          {user && user.prime_status === "active" && (
            <MSLTag
              browser={false}
              mobile={true}
              price={product.member_price}
              size={"mdx"}
            />
          )}
        </PriceRow>
        <IconWrapperMobile>
          <TagsWrapper>
            {product.is_saved && <SavedTag key="saved">{t("Saved")}</SavedTag>}
            {!product.is_saved && (
              <NotSavedTag key="notSaved">{t("Regular")}</NotSavedTag>
            )}
            {product.is_featured && <NewTag key="new">{t("New")}</NewTag>}
            {product.is_local && <LocalTag key="local">{t("Quebec")}</LocalTag>}
            {product.tags?.map((tag) => (
              <Tag key={tag.tid}>{tag.copy[lang]?.name}</Tag>
            ))}
          </TagsWrapper>

          {user && user.prime_status === "active" && (
            <MemberPriceWrapper>
              <MSLTag
                browser={true}
                mobile={false}
                price={product.member_price}
                size={"mdx"}
              />
            </MemberPriceWrapper>
          )}
        </IconWrapperMobile>
        {product.inventory > 0 && user && (
          <ActionWrapper>
            <ProductQuantitySelector
              isPreOrder={isPreOrder}
              order={order?.status === "open" ? order : undefined}
              productId={product.pid}
              quantity={productInCart || 0}
              withBorder={true}
            />
          </ActionWrapper>
        )}
        {!subscription && (
          <ActionWrapper>
            <StyledButton
              thirdReverse
              onClick={() => {
                history.push("/sign-up/step-1");
              }}
            >
              {t("GenericCTA.add")}
            </StyledButton>
          </ActionWrapper>
        )}
      </BottomWrapper>
      {product.inventory > 0 && user && (
        <ActionWrapperMobile>
          <ProductQuantitySelector
            isPreOrder={isPreOrder}
            order={order?.status === "open" ? order : undefined}
            productId={product.pid}
            quantity={productInCart || 0}
            withBorder={true}
          />
        </ActionWrapperMobile>
      )}
      {!subscription && (
        <ActionWrapperMobile>
          <StyledButton
            thirdReverse
            onClick={() => {
              history.push("/sign-up/step-1");
            }}
          >
            {t("GenericCTA.add")}
          </StyledButton>
        </ActionWrapperMobile>
      )}
    </ProductCard>
  );
};

const BestBefore = styled.div`
  position: absolute;
  bottom: 50%;
  margin: 0;
  right: -5px;
  top: -5px;

  span {
    margin: 0;
    align-self: flex-start;
    padding: ${theme.spacing[0.25]} ${theme.spacing[0.5]};
    white-space: nowrap;
    border-radius: 64px;
    border: 0; // 1px solid ${theme.colors.base.secondary};
    font-family: ${theme.font.family.secondary};
    font-size: ${theme.font.size.sm};
    line-height: ${theme.font.size.mdx};
    background: ${theme.colors.base.background};
    color: ${theme.colors.base.secondary};
  }

  @media (min-width: ${theme.breakpoints.sm}) {
  }
`;

const LabelWrapper = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: ${theme.spacing[0.25]};
`;

const ImgWrapper = styled.div`
  margin: auto;

  > img {
    cursor: pointer;
    width: 100px;
    height: 100px;
    object-fit: contain;
  }

  @media (min-width: ${theme.breakpoints.sm}) {
    flex-direction: column;
    margin-bottom: 0;

    > img {
      width: 100%;
      height: 160px;
    }
  }
`;

const TopWrapper = styled.a`
  cursor: pointer;
`;

const UnderImageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  @media (min-width: ${theme.breakpoints.sm}) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const PriceTextPromo = styled(Text)`
  font-family: ${theme.font.family.secondary};
  font-weight: ${theme.font.weight.bold};
  color: ${theme.colors.base.primary};
  line-height: ${theme.font.size.mdx};
  padding: ${theme.spacing[0.5]};
  background-color: ${theme.colors.accent.secondary};
  border-radius: ${theme.border.radius.round};
`;

const MemberPriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: end;
`;

const StyledButton = styled(Button)`
  height: 48px;
  width: 100%;
`;

const IconWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: flex-end;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  gap: ${theme.spacing[0.25]};
  margin: ${theme.spacing[0.25]} 0;
  @media (max-width: ${theme.breakpoints.sm}) {
    flex-direction: column;
    display: none;
  }
`;

const IconWrapperMobile = styled.div`
  display: none;
  flex: 1;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  gap: ${theme.spacing[0.25]};
  margin: ${theme.spacing[0.25]} 0;
  @media (max-width: ${theme.breakpoints.sm}) {
    flex-direction: column;
    display: flex;
  }
`;

const ProductCard = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing[1]};
  position: relative;
  background: ${theme.colors.base.white};
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  border-radius: 4px;

  @media (min-width: ${theme.breakpoints.sm}) {
    min-height: 480px;
    width: 256px;
  }
`;

const PromoLabel = styled(Label)`
  position: absolute;
  top: -5px;
  left: -5px;
  padding: 2px ${theme.spacing[0.5]};
  font-family: ${theme.font.family.primary};
  background: ${theme.colors.base.primary};
  border-radius: ${theme.border.radius.xl};
  color: ${theme.colors.base.white};
  text-align: center;

  @media (min-width: ${theme.breakpoints.sm}) {
    max-width: none;
    top: -5px;
    left: -5px;
    padding: ${theme.spacing[0.25]} ${theme.spacing[0.5]};
  }
`;

const NoStockLabel = styled(PromoLabel)`
  color: ${theme.colors.base.white};
  background: ${theme.colors.base.primary};
`;

const PresentationWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: ${theme.spacing[0.25]};
  align-items: center;
  min-height: none;

  > img {
    cursor: pointer;
    width: 98px;
    height: 98px;
    margin-bottom: ${theme.spacing[0.25]};
    object-fit: contain;
  }

  @media (min-width: ${theme.breakpoints.sm}) {
    margin-bottom: 0;

    > img {
      width: 100%;
      height: 160px;
    }
  }

  @media (max-width: ${theme.breakpoints.sm}) {
    flex-direction: row;
  }
`;

const InfoWrapper = styled.div`
  flex-direction: column;
  margin-left: ${theme.spacing[0.25]};
  width: 100%;

  @media (min-width: ${theme.breakpoints.sm}) {
    margin-left: 0;
  }
`;

const LocationText = styled(Text)`
  display: flex;
  flex-direction: column;
  font-family: ${theme.font.family.secondary};
  color: ${theme.colors.base.primary};
  margin: 0;
`;

const ProductLabel = styled(Text)`
  font-family: ${theme.font.family.secondary};
  margin: ${theme.spacing[0.25]} 0 ${theme.spacing[0.5]} 0;
`;

const ProductDescription = styled(Text)`
  text-align: left;
  font-family: ${theme.font.family.secondary};
  font-size: 14px;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const TagsWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: start;
  gap: 5px;

  span {
    align-self: flex-start;
    padding: ${theme.spacing[0.25]} ${theme.spacing[0.5]};
    white-space: nowrap;
    border-radius: 64px;
    border: 0;
    font-family: ${theme.font.family.secondary};
    font-size: ${theme.font.size.sm};
    line-height: ${theme.font.size.mdx};
  }
`;

const Tag = styled.span`
  background: ${theme.colors.base.background};
  color: ${theme.colors.base.secondary};
`;

const SavedTag = styled.span`
  background: ${theme.colors.base.secondary};
  color: ${theme.colors.base.white};
`;

const NotSavedTag = styled.span`
  background: ${theme.colors.status.info};
  color: ${theme.colors.base.white};
`;

const LocalTag = styled.span`
  background: #003da5;
  color: ${theme.colors.base.white};
`;

const NewTag = styled.span`
  background: ${theme.colors.base.third};
  color: ${theme.colors.base.white};
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  margin: ${theme.spacing[0.25]} 0 ${theme.spacing[0.25]} 0;
  background: ${theme.colors.accent.secondary};
  display: none;

  @media (min-width: ${theme.breakpoints.sm}) {
    display: block;
  }
`;

const BottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
  @media (max-width: ${theme.breakpoints.sm}) {
    flex-direction: column-reverse;
    flex-grow: none;
  }
`;

const PriceRow = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: ${theme.breakpoints.sm}) {
    display: none;
  }
`;

const PriceRowMobile = styled.div`
  display: none;
  justify-content: space-between;
  @media (max-width: ${theme.breakpoints.sm}) {
    display: flex;
  }
`;

const UnitPriceText = styled(Text)`
  font-family: ${theme.font.family.secondary};
  color: ${theme.colors.base.grey};
  @media (min-width: ${theme.breakpoints.sm}) {
    align-items: center;
  }
`;

const PriceText = styled(Text)`
  font-family: ${theme.font.family.secondary};
  font-weight: ${theme.font.weight.bold};
  color: ${theme.colors.base.secondary};
  line-height: ${theme.font.size.mdx};
  display: flex;
  gap: ${theme.spacing[0.5]};
  align-items: center;
`;

const PriceTextBottom = styled.span`
  color: ${theme.colors.base.primary};
`;

const ActionWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${theme.spacing[1]};
  @media (max-width: ${theme.breakpoints.sm}) {
    display: none;
  }
`;

const ActionWrapperMobile = styled.div`
  display: none;
  justify-content: center;
  margin-top: ${theme.spacing[1]};
  @media (max-width: ${theme.breakpoints.sm}) {
    display: flex;
  }
`;
